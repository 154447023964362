<template>
    <div class="form-group">
        <label class="h6 text-uppercase text-grey" :for="this.name" v-if="this.label"><small>{{this.label}}</small></label>
        <select :name="this.name" :id="this.name" class="form-control" @change="updateSelectValue" :disabled="this.disabled" :required="this.required">
            <option :value="this.default" v-if="this.default" disabled :selected="!this.selected">{{this.default}}</option>

            <option 
                :selected="self.selected === option[self.optionId]"
                v-for="(option,index) in this.options" 
                :key="index" 
                :value="self.optionId ? option[self.optionId] : self.option.text">
                    {{option[self.optionText]}}
            </option>

        </select>
    </div>
</template>

<script>
export default {
    props: [
        'name',
        'label',
        'options',
        'optionId',
        'optionText',
        'default',
        'selected',
        'disabled',
        'required',
    ],
    computed: {
        self() {
            return this
        }
    },
    methods: {
        updateSelectValue (e) {
            this.$emit('passValue', {model: this.name, value: e.target.value})
        }
    }
}
</script>

<style lang="scss">
</style>