<template>
    <b-modal v-model="showModal" centered title="New Location Detected">
      <p>We've noticed this image was taken at a different trial location to your currently set location.</p>
      
      <b-container fluid class="px-0">
        <b-row>
          <b-col cols="12" sm="6" v-if="currentLocation.town || currentLocation.region || currentLocation.state">
            <p class="mb-0 h6 text-uppercase text-grey"><small>Current Location</small></p>
            <p class="">{{currentLocation.town}}, {{currentLocation.region}}, {{currentLocation.state}}</p>
          </b-col>
          <b-col cols="12" sm="6">
            <p class="mb-0 h6 text-uppercase text-grey"><small><b>New Location</b></small></p>
            <p class="mb-0"><b>{{newLocation.town}}, {{newLocation.region}}, {{newLocation.state}}</b></p>
          </b-col>
        </b-row>
      </b-container>

      <template #modal-footer>
      <b-button size="sm" variant="outline-secondary" @click="modalAction(false)">
        Keep Location
      </b-button>
      <b-button size="sm" variant="success" class="text-white" @click="modalAction(true)">
        Update Location
      </b-button>
    </template>
    </b-modal>
</template>

<script>
export default {
  props: [
    'newLocation',
    'currentLocation',
    'showModal'
  ],
  methods: {
    modalAction(updateLocation) {
      this.$emit('handleModal', {updateLocation})
    }
  }
}
</script>