import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue-cookies'

import { BootstrapVue, BVToastPlugin, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(BVToastPlugin)
Vue.use(IconsPlugin)
Vue.use(VueCookies)
Vue.$cookies.config('7d')

Vue.prototype.$http = axios
// const token = localStorage.getItem('token');
const token = Vue.$cookies.get('nvtToken');

if(token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
