import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  // {
  //   path: '/result',
  //   name: 'Result',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Result.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.user) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      try{
        //eslint-disable-next-line
        const { status } = await store.dispatch('validate')
        next()
      }catch(e){
        store.dispatch('logout')
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        })
      }
    }
  } else {
    next()
  }
})


export default router
