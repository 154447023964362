<template>
  <b-container fluid class="px-0 position-relative" id="app">
    <b-row class="justify-content-center no-gutters">
      <b-col cols="12" class="position-relative">
          <router-view/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
    }
  },
  computed : {
    isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
  },
  methods: {
  },
  mounted () {
  },
  created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      // eslint-disable-next-line
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          // eslint-disable-next-line
          this.$store.dispatch(logout)
        }
        throw err;
      });
    });
  }
}
</script>

<style lang="scss">
  @import 'assets/scss/variables.scss';

  // Import Bootstrap and BootstrapVue source SCSS files
  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';

  @import 'assets/scss/type.scss';

</style>
