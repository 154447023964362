<template>
    <div>
        <div class="alert alert-danger" v-if="this.error">
          {{this.error}}
        </div>
        <b-form @submit="onSubmit">
          <b-row class="justify-content-center mb-3">
            <b-col cols="12" :md="showCategories ? 6 : 12">
              <SelectInput :selected="this.form.crop ||this.$store.state.formSubmission.crop" label="Crop" name="crop" default="Select Crop" optionId="CropName" optionText="CropName" :options="this.cropOptions" v-on:passValue="updateSelect" :required="true"/>
            </b-col>
            <!-- <transition name="fade">
              <b-col cols="12" md="6" v-if="showCategories">
              <SelectInput label="Crop Category" name="category" default="Select Crop Category" optionId="CropCategoryID" optionText="CropCategoryName" :options="this.categoryOptions" v-on:passValue="updateSelect"/>
              </b-col>
            </transition> -->
          </b-row>
          <b-row>
            <b-col cols="12">
              <h6 class="text-uppercase text-grey"><small>Location</small></h6>
            </b-col>
            <b-col cols="6">
              <SelectInput :selected="this.form.state || this.$store.state.formSubmission.state" name="state" default="Select State" optionId="state" optionText="state" :options="this.stateOptions" v-on:passValue="updateRegions"/>
            </b-col>
            <b-col cols="6">
              <SelectInput name="region" default="Select Region" optionId="region" optionText="region" :options="this.regionOptions" v-on:passValue="updateTowns" :selected="this.form.region || this.$store.state.formSubmission.region" :disabled="!this.form.state" :required="true"/>
            </b-col>
            <b-col cols="6">
              <b-overlay :spinner-small="true" spinner-variant="primary" :show="loadingTowns">
                <SelectInput name="town" :selected="this.form.town || this.$store.state.formSubmission.town" default="Select Town" optionId="town" optionText="town" :options="this.townOptions" v-on:passValue="updateSelect" 
                    :disabled="!this.form.region" :required="true"/>
              </b-overlay>
            </b-col>
            <b-col cols="6">
              <SelectInput name="year" :selected="this.form.year || this.$store.state.formSubmission.year" default="Select Year" optionId="year" optionText="year" :options="this.yearOptions" v-on:passValue="updateSelect" 
                 :required="true"/>
            </b-col> 
        </b-row>
        <b-row>
          <b-col class="d-flex flex-column">
            <b-button type="submit" variant="primary" class="text-white mb-2" size="lg" block>Submit</b-button>
          </b-col>  
        </b-row>
        </b-form>
    </div>
</template>

<script>
// import { mapGetters } from 'vuex'
import SelectInput from './SelectInput'

export default {
    data(){
        return {
          form: {
              url: process.env.VUE_APP_AUTH_API_URL+'/validate',
              token: this.$store.state.token,
              // files: null,
              crop: this.$store.state.formSubmission.crop ? this.$store.state.formSubmission.crop : null,
              category: null,
              state: this.$store.state.formSubmission.state ? this.$store.state.formSubmission.state : null,
              region: this.$store.state.formSubmission.region ? this.$store.state.formSubmission.region : null,
              town: this.$store.state.formSubmission.town ? this.$store.state.formSubmission.town : null,
              year: this.$store.state.formSubmission.year ? this.$store.state.formSubmission.year : new Date().getFullYear()
          },
          showCategories: false,
          cropOptions: this.$store.getters.getCropSelectOptions,
          categoryOptions: null,
          stateOptions: this.$store.getters.getStateSelectOptions,
          regionOptions: this.$store.getters.getRegionSelectOptions,
          townOptions: this.$store.state.towns,
          yearOptions: this.listOfYears(),
          loadingTowns: false,
          error: null,
        }
    },
    components: {
      SelectInput
    },
    props: [
        'files',
        'clearForm',
        'location'
    ],
    computed: {
    },
    methods: {
        onSubmit (e) {
            e.preventDefault() 

            this.error = null

            if(!this.form.crop || !this.form.state || !this.form.town || !this.files) {
              this.error = 'Please fill out all fields'
              return;
            }

            this.$store.commit('set_location', {
              state: this.form.state,
              region: this.form.region,
              town: this.form.town,
            })

            let formData = new FormData();
            formData.append('data', JSON.stringify(this.form))

            for( let i = 0; i < this.files.length; i++ ){
              let file = this.files[i];
              formData.append('files[' + i + ']', file);
            }

            this.$store.dispatch('loading', true)

            this.$store.dispatch('validate').then(() => {
              this.$store.dispatch('uploadMedia', formData).then(() => {
                this.$store.dispatch('loading', false)
                this.$store.commit('set_formsubmission', this.form)
                // this.$router.push('/result')
              })
            }).catch(() => {
              this.$store.dispatch('loading', false)
              this.$store.dispatch('logout').then(() => {
                this.$router.push('/login')
              })
            })
            
        },
        resetForm () {
            this.form = {
                crop: null,
                category: null,
                state: null,
                region: null,
                town: null,
                year: this.financialYear()
            }
            this.$emit('clearMedia');
        },
        financialYear() {
            let financial_year = "";
            let today = new Date();
            if ((today.getMonth() + 1) <= 3) {
                financial_year = (today.getFullYear() - 1)
            } else {
                financial_year = today.getFullYear()
            }
            return financial_year;
        },
        listOfYears() {
          var max = new Date().getFullYear()
          var min = max - 13
          var years = []

          for (var i = max; i >= min; i--) {
            years.push({year: i})
          }
          return years
        },
        updateSelect(e) {
          this.form[e.model] = e.value
        },
        updateCropCategories (e) {
          this.updateSelect(e)
          this.categoryOptions = this.$store.getters.getCropCategorySelectOptions(e.value)
          if(this.categoryOptions.length > 2) {
            this.showCategories = true
          } else {
            this.showCategories = false
          }
        },
        updateRegions (e) {
          this.updateSelect(e)
          this.regionOptions = this.$store.getters.getStateRegionsSelectOptions(e.value)
          this.form.region = this.$store.state.location.state === e.value ? this.$store.state.location.region : null
          this.form.town = this.form.region !== this.$store.state.location.region ? null : this.$store.state.location.town
          
        },
        updateTowns (e) {
          this.updateSelect(e)
          this.loadingTowns = true
          this.form.town = null
           this.$store.dispatch('getRegionsTowns', e.value).then(() => {
             this.townOptions = this.$store.state.towns
             this.loadingTowns = false
           }).catch((err) => {
             this.loadingTowns = false
             console.log(err)
           })
        }
    },
    mounted () {
      this.form.files = this.files
      // console.log(this.form)
    },
    watch: {
        location: function() {
              this.form.state = this.location.state
              const state = {value: this.location.state}
              this.updateRegions(state)


              // const currentTown = this.$store.state.towns.filter(town => {
              //   // return town.town === 'Mintaro'
              //   return town.town === this.$store.state.location.town
              // })

              // console.log('current location')
              // console.log(this.$store.state.location)

              // if(currentTown.length > 0) {
                this.form.region = this.$store.state.location.region

                this.form.town = this.$store.state.location.town

                this.$store.dispatch('getRegionsTowns', this.$store.state.location.region).then(() => {
                  this.townOptions = this.$store.state.towns
                  this.loadingTowns = false
                }).catch((err) => {
                  this.loadingTowns = false
                  console.log(err)
                })

                
              // }
        },
        files: function () {
            this.form.files = this.files
            // this.form.region = null
            // this.form.town = null
        },
        clearForm: function() {
            if(this.clearForm === true){
              this.resetForm()
            }
        }

    }
}
</script>

<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>