<template>
  <div class="result">
    <HeroContainer>
      <CircleContainer :bgColor="this.status === 'success' ? 'success' : 'danger'">
        <img src="@/assets/images/error.svg" alt="" />
      </CircleContainer>
    </HeroContainer>
    <b-container>
      <b-row>
        <b-col cols="12" class="pt-4 text-center" v-if="this.status !== 'success'">
          <h3 class="text-danger">Error</h3>
          <h2 class="mb-3">Oops, Something Went Wrong</h2>
          <b-button variant="primary" @click="goBack()" class="text-white btn-maxwidth" block>Try Again</b-button>
        </b-col>
        <b-col cols="12" class="pt-4 text-center" v-else>
          <h3 class="text-success">Success</h3>
          <h2 class="mb-3">Media Uploaded</h2>

          <p class="mb-0 h6 text-uppercase text-grey"><small><b>Trial Location</b></small></p>
          <p><b>{{this.submission.town}}, {{this.submission.region}}, {{this.submission.state}}</b></p>

          <b-button variant="outline-secondary" :href="this.$store.state.resultUrl+'?nocollapseomatic=1#filter-year='+ this.submission.year +'&filter-location='+this.submission.town.replace(/\s/g, '')" class="mr-2" target="_blank">View Map</b-button>
          <b-button variant="primary" @click="goBack(true)" class="text-white">Add More</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HeroContainer from '@/components/HeroContainer'
import CircleContainer from '@/components/CircleContainer'

export default {
  props: [
    'status',
    'submission'
  ],
  components: {
    HeroContainer,
    CircleContainer
  },
  computed: {
  },
  methods: {
    goBack (clearMedia) {
      this.$store.commit('set_displayresult', false)
      this.$store.commit('set_status', '')

      if(clearMedia === true) {
        this.$emit('clearMedia')
      }
    }
  },
  mounted () {
  },
  unmounted() {
  },
}
</script>

<style lang="scss">
  .btn-maxwidth {
    max-width:250px;
    margin-left:auto;
    margin-right:auto;
  }
</style>