<template>
    <div class="save-app py-4 px-5" v-bind:class="display ? 'save-app--show' : null"  @click="hideMessage()">
        <div class="save-app__content mx-auto text-center">
            <p class="mb-0">To add this app to your home screen, open the browser settings <span class="text-primary"><b-icon icon="three-dots-vertical" /></span> and select 'Add To Homescreen'.</p>
        </div>
        <b-button variant="link text-secondary" class="save-app__close" @click="hideMessage()"><b-icon icon="x" font-scale="1.5"/></b-button>
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
export default {
    data () {
        return {
            display: false,
        }
    },
    methods: {
        showMessage: function () {
            const $this = this;
            const cookie = this.$cookies.get('nvtHideAppMessage');
            if (isMobile && !cookie && this.display == false) {
                setTimeout(function () {
                    $this.display = true;
                }, 4000)
            }
        },
        hideMessage: function () {
            this.$cookies.set('nvtHideAppMessage', true, '30d')
            this.display = false;
        }
    },
    mounted () {
        this.showMessage();
    }
}
</script>

<style lang="scss">
  @import '../assets/scss/variables.scss';

  // Import Bootstrap and BootstrapVue source SCSS files
  @import '~bootstrap/scss/bootstrap.scss';
  @import '~bootstrap-vue/src/index.scss';
    .save-app {
        background-color: #fff;
        border-top: 2px solid theme-color(primary);
        position: fixed;
        width:100%;
        bottom:0;
        left:0;
        z-index:10;
        transform: translateY(100%);
        opacity:0;
        transition: all 1.5s ease;
        cursor: pointer;

        &--show {
            transform: translateY(0);
            opacity:1;
        }
    }

    .save-app__content {
        max-width: 300px;
        margin-left:auto;
        margin-right:auto;

        p {
            font-size: 0.85rem;
        }
    }

    .save-app__close {
        position:absolute;
        top: 5px;
        right:5px;
    }
</style>