<template>
  <div class="home pb-5 position-relative">
    <SaveApp/>

      <div v-if="!this.$store.state.displayResult">
        <HeroContainer>
                  <input type="file" ref="file" multiple accept="image/*, video/*" style="display: none" @change="addImages">
                 
                  <div @click="$refs.file.click()" @dragover.prevent="dragFiles" @dragexit.prevent="dragLeave" @drop.prevent="dropFiles" class="img-container" v-bind:class="!this.dragging ? 'bg-primary' : 'bg-secondary'" v-bind:style="{ backgroundImage: 'url(' + this.previewImage + ')' }">
                    
                    <img src="@/assets/images/add-photo.svg" class="hide-from-drag" alt="" v-if="!this.previewImage">
                    <span class="h6 text-uppercase text-white mt-1 hide-from-drag" v-if="!this.previewImage">
                      <small>
                        <span v-if="!this.dragging">Add</span>
                        <span v-else>Drop</span> Media
                      </small>
                    </span>
                    
                    <div class="img-container__total" v-if="this.files">{{this.files.length}}</div>
                  </div>
                  <transition name="fade">
                    <b-button size="sm" class="mt-3" variant="outline-danger" v-if="this.files && this.files.length >= 1" @click="clearPhotos">Clear Photo<span v-if="this.files.length > 1">s</span></b-button>
                  </transition>
          </HeroContainer>

        <b-container>
            <UploadForm :files="this.files" :clearForm="this.clear" :location="this.$store.state.location" v-if="!this.$store.state.isLoading"/>
            <div class="text-center my-5" v-else>
              <!-- <b-spinner class="m-5" variant="primary" label="Busy"></b-spinner> -->
              <p class="h6 text-uppercase text-grey"><small>Upload Progress</small></p>
              <b-progress :value="this.$store.state.uploadProgress" max="100" show-progress animated variant="success"></b-progress>
            </div>
        </b-container>

        <UpdateLocationModal :showModal="this.openModal" :currentLocation="this.$store.state.location" :newLocation="this.detectedMediaLocation" v-if="this.detectedMediaLocation" v-on:handleModal="updateLocation"/>
      </div>

      <div v-else>
        <Result :status="this.$store.state.status" :submission="this.$store.state.formSubmission" v-on:clearMedia="clearPhotos"/>
      </div>

      <Footer />
  </div>
</template>

<script>
import SaveApp from '@/components/SaveApp'
import HeroContainer from '@/components/HeroContainer'
import UploadForm from '@/components/UploadForm'
import UpdateLocationModal from '@/components/UpdateLocationModal'
import Result from '@/components/Result'
import Footer from '@/components/Footer'
import exifr from 'exifr'

export default {
  name: 'Home',
  data () {
    return {
      files: null,
      previewImage: null,
      clear: false,
      error: null,
      detectedMediaLocation: {},
      openModal: false,
      dragging: false
    }
  },
  components: {
    SaveApp,
    HeroContainer,
    UploadForm,
    UpdateLocationModal,
    Result,
    Footer
  },
  computed: {
    location() {
      return this.$store.state.location
    }
  },
  methods: {
    dragFiles () {
      this.dragging = true
    },
    dragLeave () {
      this.dragging = false
    },
    dropFiles (e) {
      this.addImages(e, true)
    },
    addImages (e, dropped) {
      this.files = dropped ? e.dataTransfer.files : e.target.files
      if (this.files[0]) {
          // this.files = dropped ? e.dataTransfer.files : e.target.files

          this.previewImage = (this.files[0].type === 'images/jpg' || this.files[0].type === 'image/jpeg' || this.files[0].type === 'image/png' ? URL.createObjectURL(this.files[0]) : './img/video-placeholder.jpg')
          this.clear = false

          var i
          var gpsResult
          for (i = 0; i < this.files.length; i++) {

            if(this.files[i].type === 'images/jpg' || this.files[i].type === 'image/jpeg' || this.files[i].type === 'image/png') {
              gpsResult = exifr.gps(this.files[i]).then( (location) => {
                  if(!location) {
                    return
                  }
                  // this.$store.dispatch('loading', true)
                  this.$store.dispatch('getClosestLocation', {
                    lat: location.latitude,
                    long: location.longitude,
                    update: false
                  }).then((response) => {
                    this.detectedMediaLocation = response.data
                    if(
                      this.detectedMediaLocation.state !== this.$store.state.location.state || 
                      this.detectedMediaLocation.region !== this.$store.state.location.region || 
                      this.detectedMediaLocation.town !== this.$store.state.location.town
                      ) {
                      // this.$bvModal.show('modal-location')
                      this.openModal = true
                    }
                    // this.$store.dispatch('loading', false)
                  }
                  )
              })
            }
            
            if(gpsResult) {
              break
            }
          }

      } else {
          this.error = 'Please upload an image'
      }
    },
    updateLocation (action) {
      this.openModal = false
      if(action.updateLocation) {
        this.$store.commit('set_location', this.detectedMediaLocation)
        this.displayToast (
          'Location Updated', 
          this.$store.state.location.town + ', ' + 
          this.$store.state.location.region + ', ' +
          this.$store.state.location.state
        )
      }
    },
    displayToast (title, message, variant) {
        this.$bvToast.toast(message, {
          title: title ? title : null,
          variant: variant ? variant : "success",
          solid: true,
          appendToast: true
        })
    },
    clearForm () {
      // this.clear = true
    },
    clearPhotos () {
      this.files = null
      this.previewImage = null
      this.dragging = false
    }
  },
  created () {
    this.$store.dispatch('loading', true)
    this.$store.dispatch('getCrops').then(() => {
      this.$store.dispatch('getBaseData').then(() => {
        this.$store.dispatch('loading', false)

        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.$store.dispatch('getClosestLocation', {
              lat: position.coords.latitude,
              long: position.coords.longitude
            }).then(() => {
              this.displayToast (
                'Location Set', 
                this.$store.state.location.town + ', ' + 
                this.$store.state.location.region + ', ' +
                this.$store.state.location.state
              )
            })
          },
          error => {
            console.warn(error.message);
            this.$store.dispatch('loading', false);
          }
        )  

      }).catch(error => {
        console.error(error)
      })
    })
  },
  mounted () {
    // Initialise crops
    this.$store.commit('set_status', '')
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables.scss';

  .img-container {
    cursor:pointer;
    position:relative;
    width:150px;
    height:150px;
    border-radius:50%;
    background-color: $primary;
    display:flex;
    flex-direction:column;
    border: 5px solid $light-grey;
    justify-content:center;
    align-items:center;
    background-size: cover;
    background-position:center center;
    transition: background-color .3s ease-in-out
  }

  .img-container__total {
    position:absolute;
    background-color: $primary;
    color: $white;
    font-size: 15px;
    height:30px;
    width:30px;
    line-height: 30px;
    text-align:center;
    border-radius:50%;
    top:0;
    right:0;
  }

  .hide-from-drag {
    pointer-events: none;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

</style>
