var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"bg-white pt-5 px-5 pb-4 pb-lg-5 position-relative d-flex flex-column align-items-center justify-content-center"},[(_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name !== 'Login';
}))?_c('router-link',{staticClass:"brand  text-grey",attrs:{"to":'/'}},[_c('img',{staticClass:"img-fluid brand__media-uploader",attrs:{"src":require("@/assets/images/media-uploader.png"),"srcset":require("@/assets/images/media-uploader.png") + " 1x, " + require("@/assets/images/media-uploader@2x.png") + " 2x","alt":""}})]):_vm._e(),(!_vm.hideProfile)?_c('router-link',{staticClass:"profile-link",class:[_vm.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Profile';
}) ? 'text-primary': 'text-grey'],attrs:{"to":"/profile"}},[_c('b-icon',{attrs:{"icon":"person-circle","font-scale":"1.5"}})],1):_vm._e(),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }