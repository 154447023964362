<template>
    <div class="mb-4">
        <div class="bg-white pt-5 px-5 pb-4 pb-lg-5 position-relative d-flex flex-column align-items-center justify-content-center">
          
            <router-link :to="'/'" class="brand  text-grey" v-if="$route.matched.some(({ name }) => name !== 'Login')">
                <img src="@/assets/images/media-uploader.png" srcset="@/assets/images/media-uploader.png 1x, @/assets/images/media-uploader@2x.png 2x" alt="" class="img-fluid brand__media-uploader">
            </router-link>
                
            <router-link to="/profile" class="profile-link" v-bind:class="[$route.matched.some(({ name }) => name === 'Profile') ? 'text-primary': 'text-grey']" v-if="!hideProfile"><b-icon icon="person-circle" font-scale="1.5"></b-icon></router-link>
            <slot></slot>
        </div>
        <!-- <ArcBase/> -->
    </div>
</template>

<script>
// import ArcBase from '@/components/ArcBase.vue'

export default {
    props: {
        hideProfile: Boolean,
        back: String
    },
    components: {
        // ArcBase
    },
    // data () {
    //     return {
    //         file: undefined
    //     }
    // },
    methods: {
        addMedia (e) {
            this.$emit('updateFile', e.target.files[0]);
        }
    },
    mounted () {
    }
}
</script>

<style lang="scss" scoped>
    .brand {
        position:absolute;
        top:15px;
        left:15px;
        line-height:10px;

        &__media-uploader {
            max-width:130px;
        }
    }

    .profile-link {
        position:absolute;
        top:15px;
        right: 15px;
    }
</style>