import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueCookies from 'vue-cookies'
Vue.use(Vuex)
Vue.use(VueCookies)

function getUniqueValues(data, key) {
    var flags = [], output = [], l = data.length, i;
    for( i=0; i<l; i++) {
        if( flags[data[i][key]]) continue;
        flags[data[i][key]] = true;
        output.push(data[i]);
    }
    return output
}

export default new Vuex.Store({
  state: {
    status: '',
    displayResult: false,
    // token: localStorage.getItem('token') || '',
    // user: JSON.parse(localStorage.getItem('user')) || null,
    token: Vue.$cookies.get('nvtToken') || '',
    user: Vue.$cookies.get('nvtUser') || null,
    isLoading: false,
    crops: [],
    trialLocations: [],
    towns: [],
    location: {},
    formSubmission: {},
    uploadProgress: 0,
    resultUrl: process.env.VUE_APP_RESULTS_URL
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, payload){
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    clear_user(state) {
      state.user = null
    },
    set_status(state, payload) {
      state.status = payload
    },
    set_displayresult(state, payload) {
      state.displayResult = payload
    },
    set_crops(state, payload){
      state.crops = payload
    },
    set_trialLocations(state, payload) {
      state.trialLocations = payload
    },
    set_towns(state, payload){
      state.towns = payload
    },
    set_location(state, payload) {
      state.location = payload
    },
    set_loading(state, payload) {
      state.isLoading = payload
    },
    set_formsubmission(state, payload) {
      state.formSubmission = payload
    },
    set_uploadprogress(state, payload) {
      state.uploadProgress = payload
    }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    allCrops: state => state.crops,
    getCropSelectOptions: state => {
      return getUniqueValues(state.crops, 'CropID')
    },
    // eslint-disable-next-line
    getCropCategorySelectOptions: state  => selectedCrop => {
      const selectedCategories = state.crops.filter(category => {
        return category.CropID === selectedCrop
      })
      return selectedCategories
    },
    getStateSelectOptions: state => {
      return getUniqueValues(state.trialLocations, 'state')
    },
    getRegionSelectOptions: state => {
      return getUniqueValues(state.trialLocations, 'region')
    },
    // eslint-disable-next-line
    getStateRegionsSelectOptions: state  => selectedState => {
      const selectedRegions = state.trialLocations.filter(location => {
        return location.state === selectedState
      })

      return getUniqueValues(selectedRegions, 'region')
    }
  },
  actions: {
    loading({commit}, loading) {
      commit('set_loading', loading);
    },
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: process.env.VUE_APP_AUTH_API_URL, data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.token
          const user = resp.data
          // console.log(resp.data)

          const allowedRoles = ['administrator', 'nvt_administrator', 'super_admin', 'public_area_editor']

          if(resp.data.roles && resp.data.roles.some(r=> allowedRoles.includes(r))){
            Vue.$cookies.set('nvtToken', token)
            Vue.$cookies.set('nvtUser', user)
            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', {token, user})
            resolve(resp)
          } else {
            commit('auth_error')
            reject('You don\'t have the correct permissions')
          }
          
        })
        .catch(err => {
          Vue.$cookies.remove('nvtToken')
          Vue.$cookies.remove('nvtUser')
          delete axios.defaults.headers.common['Authorization']
          commit('auth_error')
          reject(err)
        })
      })
    },
    logout({commit}){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        commit('logout')
        commit('clear_user')
          Vue.$cookies.remove('nvtToken')
          Vue.$cookies.remove('nvtUser')
          delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    validate({ state }) {
      return axios({
        url: `${process.env.VUE_APP_AUTH_API_URL}/validate`, 
        method: 'post',
        headers: {
          'Authorization': `Bearer ${state.token}`
        }
      })
    },
    // eslint-disable-next-line
    getCrops({commit}){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_DATA_API_URL + '/crops-and-categories/')
        .then(response => {
          commit('set_crops', response.data.crops)
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    // eslint-disable-next-line
    getBaseData({commit}){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_DATA_API_URL + '/filters/base')
        .then(response => {
          // commit('set_crops', response.data.crop)
          commit('set_trialLocations', response.data.region)
          commit('set_towns', response.data.town)
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    getClosestLocation({commit}, location) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_DATA_API_URL}/find-closest-location/?lat=${location.lat}&long=${location.long}`)
        .then(response => {
          if(location.update !== false) {
            commit('set_location', response.data)
          }
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    getRegionsTowns({commit}, region){
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_DATA_API_URL}/find-regions-towns/?region=${region}`)
        .then(response => {
          commit('set_towns', response.data.towns)
          resolve(response)
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },
    //eslint-disable-next-line
    uploadMedia({commit, state}, data) {
      return new Promise((resolve, reject) => {

        axios({url: process.env.VUE_APP_UPLOAD_URL, data: data, headers: {
          'Content-Type': 'multipart/form-data'
        }, method: 'POST', onUploadProgress: function (progressEvent) {
          commit('set_uploadprogress', parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )))
        } })
        .then(resp => {
          // console.log(resp.data)
          commit('set_displayresult', true)
          commit('set_uploadprogress', 0)

          if(resp.data.status !== 'success') {
            commit('set_status', 'error')
          } else {
            commit('set_status', 'success')
          }
          resolve(resp)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
      })
    }
  },
  modules: {
  }
})
