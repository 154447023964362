<template>
    <div class="img-container" v-bind:class="this.bgColor ? 'bg-'+this.bgColor : 'bg-primary'" v-bind:style=" { backgroundImage: 'url(' + this.bgImg + ')' }">
        <slot v-if="!this.bgImg"></slot>
    </div>
</template>

<script>

export default {
    props: [
        'bgImg',
        'bgColor',
        'icon'
    ],
    components: {
    },
methods: {
    },
    mounted () {
    }
}
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables.scss';

  .img-container {
    cursor:pointer;
    width:150px;
    height:150px;
    border-radius:50%;
    display:flex;
    border: 5px solid $light-grey;
    justify-content:center;
    align-items:center;
    background-size: cover;
    background-position:center center;
  }
</style>